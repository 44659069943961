export const translates = {
    AddressBookButtonsCancel: 'AddressBook.Buttons.Cancel',
    AddressBookButtonsSubmit: 'AddressBook.Buttons.Submit',
    AddressBookLabelsAdd: 'AddressBook.Labels.Add',
    AddressBookLabelsAddress: 'AddressBook.Labels.Address',
    AddressBookLabelsCompanyCode: 'AddressBook.Labels.CompanyCode',
    AddressBookLabelsCompanyName: 'AddressBook.Labels.CompanyName',
    AddressBookLabelsCompanyZipCode: 'AddressBook.Labels.CompanyZipCode',
    AddressBookLabelsEdit: 'AddressBook.Labels.Edit',
    AddressBookLabelsEmail: 'AddressBook.Labels.Email',
    AddressBookLabelsEmptyList: 'AddressBook.Labels.EmptyList',
    AddressBookLabelsIBAN: 'AddressBook.Labels.IBAN',
    AddressBookLabelsPhone: 'AddressBook.Labels.Phone',
    AddressBookTitlesCreate: 'AddressBook.Titles.Create',
    AddressBookTitlesPreview: 'AddressBook.Titles.Preview',
    AddressBookTitlesUpdate: 'AddressBook.Titles.Update',
    BidLabelsPerHour: 'Bid.Labels.PerHour',
    ContactsFormAdd: 'Contacts.Form.Add',
    ContactsFormDelete: 'Contacts.Form.Delete',
    ContactsFormEmail: 'Contacts.Form.Email',
    ContactsFormName: 'Contacts.Form.Name',
    ContactsFormPosition: 'Contacts.Form.Position',
    ContactsFormTitle: 'Contacts.Form.Title',
    ContentsConfirmBid: 'Contents.ConfirmBid',
    CreateOrderBiddingTypesContest: 'CreateOrder.BiddingTypes.Contest',
    CreateOrderFavoritePartnerSelected: 'CreateOrder.FavoritePartner.Selected',
    CreateOrderFavoritePartnerSelect: 'CreateOrder.FavoritePartner.Select',
    CreateOrderBiddingTypesContestDescription: 'CreateOrder.BiddingTypes.ContestDescription',
    CreateOrderBiddingTypesFactual: 'CreateOrder.BiddingTypes.Factual',
    CreateOrderBiddingTypesFactualDescription: 'CreateOrder.BiddingTypes.FactualDescription',
    CreateOrderBiddingTypesStandart: 'CreateOrder.BiddingTypes.Standart',
    CreateOrderBiddingTypesStandartDescription: 'CreateOrder.BiddingTypes.StandartDescription',
    CreateOrderButtonsAdditionalInformation: 'CreateOrder.Buttons.AdditionalInformation',
    CreateOrderButtonsBack: 'CreateOrder.Buttons.Back',
    CreateOrderButtonsCancel: 'CreateOrder.Buttons.Cancel',
    CreateOrderButtonsGetOffer: 'CreateOrder.Buttons.GetOffer',
    CreateOrderButtonsNoPhotosCancel: 'CreateOrder.Buttons.NoPhotosCancel',
    CreateOrderButtonsNoPhotosOk: 'CreateOrder.Buttons.NoPhotosOk',
    CreateOrderButtonsPinOnMap: 'CreateOrder.Buttons.PinOnMap',
    CreateOrderButtonsServiceUnavailable: 'CreateOrder.Buttons.ServiceUnavailable',
    CreateOrderButtonsSubmit: 'CreateOrder.Buttons.Submit',
    CreateOrderButtonsSubmitOrder: 'CreateOrder.Buttons.SubmitOrder',
    CreateOrderButtonsSubmitOrderEdit: 'CreateOrder.Buttons.SubmitOrderEdit',
    CreateOrderDeadlinesD: 'CreateOrder.Deadlines.D',
    CreateOrderDeadlinesH: 'CreateOrder.Deadlines.H',
    CreateOrderDeadlinesM: 'CreateOrder.Deadlines.M',
    CreateOrderDeadlinesUntilPartnerIsChosen: 'CreateOrder.Deadlines.UntilPartnerIsChosen',
    CreateOrderDeadlinesW: 'CreateOrder.Deadlines.W',
    CreateOrderLabelsAdditionalInformation: 'CreateOrder.Labels.AdditionalInformation',
    CreateOrderLabelsAddress: 'CreateOrder.Labels.Address',
    CreateOrderLabelsBiddingDeadline: 'CreateOrder.Labels.BiddingDeadline',
    CreateOrderLabelsBiddingDeadlineHelp: 'CreateOrder.Labels.BiddingDeadlineHelp',
    CreateOrderLabelsBiddingDeadlineUntil: 'CreateOrder.Labels.BiddingDeadlineUntil',
    CreateOrderLabelsDeadline: 'CreateOrder.Labels.Deadline',
    CreateOrderLabelsDescription: 'CreateOrder.Labels.Description',
    CreateOrderLabelsJobsFinished: 'CreateOrder.Labels.JobsFinished',
    CreateOrderLabelsNoPhotos: 'CreateOrder.Labels.NoPhotos',
    CreateOrderLabelsPartnerPrice: 'CreateOrder.Labels.PartnerPrice',
    CreateOrderLabelsSelectServiceReceiver: 'CreateOrder.Labels.SelectServiceReceiver',
    CreateOrderLabelsService: 'CreateOrder.Labels.Service',
    CreateOrderLabelsServiceUnavailable: 'CreateOrder.Labels.ServiceUnavailable',
    CreateOrderLabelsType: 'CreateOrder.Labels.BiddingType',
    CreateOrderLabelsTypeHelp: 'CreateOrder.Labels.BiddingTypeHelp',
    CreateOrderSubtitlesGetOffer: 'CreateOrder.Subtitles.GetOffer',
    CreateOrderSubtitlesSubmitOrder: 'CreateOrder.Subtitles.SubmitOrder',
    CreateOrderTitlesMain: 'CreateOrder.Titles.Main',
    CreateOrderTitlesNoPhotos: 'CreateOrder.Titles.NoPhotos',
    CreateOrderTitlesServiceUnavailable: 'CreateOrder.Titles.ServiceUnavailable',
    ExternalErrorUnexpected: 'External.Error.Unexpected',
    FooterLabelsCopyright: 'Footer.Labels.Copyright',
    FormsErrorsInvalidEmail: 'Forms.Errors.InvalidEmail',
    FormsErrorsMaxLengthForCompanyCode: 'Forms.Errors.MaxLengthForCompanyCode',
    FormsErrorsMustAgree: 'Forms.Errors.MustAgree',
    FormsErrorsPasswordMustMatch: 'Forms.Errors.PasswordMustMatch',
    FormsErrorsPhoneNumberInvalid: 'Forms.Errors.PhoneNumberInvalid',
    FormsErrorsRequired: 'Forms.Errors.Required',
    FormsErrorsRequiredForCompany: 'Forms.Errors.RequiredForCompany',
    FormsErrorsTooLong: 'Forms.Errors.TooLong',
    GlobalLabelsLoading: 'Global.Labels.Loading',
    GlobalLabelsNo: 'Global.Labels.No',
    GlobalLabelsNoOptions: 'Global.Labels.NoOptions',
    GlobalLabelsTimeUnitsDays: 'Global.Labels.TimeUnitsDays',
    GlobalLabelsTimeUnitsHours: 'Global.Labels.TimeUnitsHours',
    GlobalLabelsTimeUnitsMinutes: 'Global.Labels.TimeUnitsMinutes',
    GlobalLabelsTimeUnitsMonths: 'Global.Labels.TimeUnitsMonths',
    GlobalLabelsTimeUnitsSeconds: 'Global.Labels.TimeUnitsSeconds',
    GlobalLabelsTimeUnitsYears: 'Global.Labels.TimeUnitsYears',
    GlobalLabelsYes: 'Global.Labels.Yes',
    GlobalMessagesGeolocationParseError: 'Global.Messages.GeolocationParseError',
    GlobalMessagesServerError: 'Global.Messages.ServerError',
    InvitePartnerBannerButton: 'InvitePartner.Banner.Button',
    InvitePartnerSuccessTitle: 'InvitePartner.Success.Title',
    InvitePartnerSuccessSubtitle: 'InvitePartner.Success.Subtitle',
    InvitePartnerSuccessButton: 'InvitePartner.Success.Button',
    InvitePartnerBannerSubtitle: 'InvitePartner.Banner.Subtitle',
    InvitePartnerBannerTitle: 'InvitePartner.Banner.Title',
    InvitePartnerButtonsInvite: 'InvitePartner.Buttons.Invite',
    InvitePartnerCardDeleteButton: 'InvitePartner.Card.DeleteButton',
    InvitePartnerCardDismissButton: 'InvitePartner.Card.DismissButton',
    InvitePartnerCardInviteButton: 'InvitePartner.Card.InviteButton',
    InvitePartnerCardPlaceholderText: 'InvitePartner.Card.PlaceholderText',
    InvitePartnerDescriptionLoading: 'InvitePartner.Description.Loading',
    InvitePartnerDescriptionMain: 'InvitePartner.Description.Main',
    InvitePartnerDescriptionPartnerless: 'InvitePartner.Description.Partnerless',
    InvitePartnerDescriptionFoundPartner: 'InvitePartner.Description.FoundPartner',
    InvitePartnerDescriptionFoundUnavailablePartner: 'InvitePartner.Description.FoundUnavailablePartner',
    InvitePartnerLabelsPhoneInput: 'InvitePartner.Labels.PhoneInput',
    InvitePartnerLabelsTextInput: 'InvitePartner.Labels.TextInput',
    InvitePartnerRemoveCancelButton: 'InvitePartner.Remove.CancelButton',
    InvitePartnerRemoveSubmitButton: 'InvitePartner.Remove.SubmitButton',
    InvitePartnerRemoveTitle: 'InvitePartner.Remove.Title',
    InvitePartnerTitlesInvitation: 'InvitePartner.Titles.Invitation',
    InvitePartnerTitlesFoundPartner: 'InvitePartner.Titles.FoundPartner',
    InvitePartnerTitlesMain: 'InvitePartner.Titles.Main',
    LoginButtonsContinue: 'Login.Buttons.Continue',
    LoginButtonsRegister: 'Login.Buttons.Register',
    LoginButtonsReset: 'Login.Buttons.Reset',
    LoginButtonsSelectCountry: 'Login.Buttons.SelectCountry',
    LoginButtonsSubmit: 'Login.Buttons.Submit',
    LoginErrorUserBlocked: 'Login.Error.UserBlocked',
    LoginErrorCrossCountry: 'Login.Error.CrossCountry',
    LoginErrorMessagesFailed: 'Login.ErrorMessages.Failed',
    LoginLabelsDontReceiveCode: 'Login.Labels.DontReceiveCode',
    LoginLabelsEmail: 'Login.Labels.Email',
    LoginLabelsGoBack: 'Login.Labels.GoBack',
    LoginLabelsLoginOtherWay: 'Login.Labels.LoginOtherWay',
    LoginLabelsPassword: 'Login.Labels.Password',
    LoginLabelsSendAgain: 'Login.Labels.SendAgain',
    LoginLabelsWithEmail: 'Login.Labels.WithEmail',
    LoginLabelsWithPhone: 'Login.Labels.WithPhone',
    LoginSubTitlesMain: 'Login.SubTitles.Main',
    LoginTextsRegister: 'Login.Texts.Register',
    LoginTitlesMain: 'Login.Titles.Main',
    LoginTitlesSmsConfirmationSubtitle: 'Login.Titles.SmsConfirmationSubtitle',
    MenuButtonsAddressBook: 'Menu.Buttons.AddressBook',
    MenuButtonsCreateOrder: 'Menu.Buttons.CreateOrder',
    MenuButtonsLogin: 'Menu.Buttons.Login',
    MenuButtonsLogout: 'Menu.Buttons.Logout',
    MenuButtonsProfile: 'Menu.Buttons.Profile',
    MenuLinksInvitePartner: 'Menu.Links.InvitePartner',
    MenuLinksOrders: 'Menu.Links.Orders',
    NoOrdersButtonsCreate: 'NoOrders.Buttons.Create',
    NoOrdersSubtitlesMain: 'NoOrders.Subtitles.Main',
    NoOrdersTitlesMain: 'NoOrders.Titles.Main',
    OrderCancelNoReasonDescription: 'Order.CancelReason.NoReasonDescription',
    OrderCardButtonsAcceptWork: 'OrderCard.Buttons.AcceptWork',
    OrderCardButtonsAgreeModalCancel: 'OrderCard.Buttons.AgreeModalCancel',
    OrderCardButtonsAgreeModalOk: 'OrderCard.Buttons.AgreeModalOk',
    OrderCardButtonsAwaitingPay: 'OrderCard.Buttons.AwaitingPay',
    OrderCardButtonsCancelModalCancel: 'OrderCard.Buttons.CancelModalCancel',
    OrderCardButtonsCancelModalOk: 'OrderCard.Buttons.CancelModalOk',
    OrderCardButtonsCancelOrder: 'OrderCard.Buttons.CancelOrder',
    OrderCardButtonsEditOrder: 'OrderCard.Buttons.EditOrder',
    OrderCardButtonsFinishProfile: 'OrderCard.Buttons.FinishProfile',
    OrderCardButtonsLessInfo: 'OrderCard.Buttons.LessInfo',
    OrderCardButtonsMoreInfo: 'OrderCard.Buttons.MoreInfo',
    OrderCardButtonsPay: 'OrderCard.Buttons.Pay',
    OrderCardButtonsPaymentProcessing: 'OrderCard.Buttons.PaymentProcessing',
    OrderCardButtonsPreviewOffer: 'OrderCard.Buttons.PreviewOffer',
    OrderCardButtonsProfileSavingProcessing: 'OrderCard.Buttons.ProfileSavingProcessing',
    OrderCardButtonsRecreateOrder: 'OrderCard.Buttons.RecreateOrder',
    OrderCardHeaderLabelsOffersCount: 'OrderCardHeader.Labels.OffersCount',
    OrderCardHeaderLabelsTimeLeft: 'OrderCardHeader.Labels.TimeLeft',
    OrderCardLabelsAddress: 'OrderCard.Labels.Address',
    OrderCardLabelsBiddingDeadline: 'OrderCard.Labels.BiddingDeadline',
    OrderCardLabelsCancelModalContent: 'OrderCard.Labels.CancelModalContent',
    OrderCardLabelsConfirmEditModalContent: 'OrderCard.Labels.ConfirmEditModalContent',
    OrderCardLabelsDeadline: 'OrderCard.Labels.Deadline',
    OrderCardLabelsOrderAgreedPricing: 'OrderCard.Labels.AgreedPricing',
    OrderCardLabelsOrderCode: 'OrderCard.Labels.OrderCode',
    OrderCardLabelsOrderPreliminaryPricing: 'OrderCard.Labels.PreliminaryPricing',
    OrderCardLabelsOrderPricing: 'OrderCard.Labels.Pricing',
    OrderCardLabelsOrderPricingMaterials: 'OrderCard.Labels.PricingMaterials',
    OrderCardLabelsOrderPricingWork: 'OrderCard.Labels.PricingWork',
    OrderCardLabelsPartnersOffers: 'OrderCard.Labels.PartnersOffers',
    OrderCardPartnerLabelsTotalWorkCount: 'OrderTabsPartnerTab.Labels.TotalWorkCount',
    OrderCardPartnerTitlesComment: 'OrderTabsPartnerTab.Titles.Comment',
    OrderEditButtonsCancel: 'OrderEdit.Buttons.Cancel',
    OrderEditButtonsSave: 'OrderEdit.Buttons.Save',
    OrderEditSubtitlesMain: 'OrderEdit.Subtitles.Main',
    OrderEditTitlesMain: 'OrderEdit.Titles.Main',
    OrderFeedbackComment: 'Order.Feedback.Comment',
    OrderFeedbackSubmit: 'Order.Feedback.Submit',
    OrderFeedbackTitle: 'Order.Feedback.Title',
    OrderIncompleteProfileButtonsUpdate: 'Order.Buttons.CompleteProfile',
    OrderIncompleteProfileSubtitlesMain: 'Order.Subtitles.IncompleteProfile',
    OrderIncompleteProfileTitlesMain: 'Order.Titles.IncompleteProfile',
    OrderProgressLabelsAwaitingApproval: 'OrderProgress.Labels.AwaitingApproval',
    OrderProgressLabelsCompleted: 'OrderProgress.Labels.Completed',
    OrderProgressLabelsInProgress: 'OrderProgress.Labels.InProgress',
    OrderProgressLabelsResolved: 'OrderProgress.Labels.Resolved',
    OrderProgressLabelsStatusCreated: 'OrderProgress.Labels.StatusCreated',
    OrderProgressSubtitlesAwaitingApproval: 'OrderProgress.Subtitles.AwaitingApproval',
    OrderProgressSubtitlesCompleted: 'OrderProgress.Subtitles.Completed',
    OrderProgressSubtitlesInProgress: 'OrderProgress.Subtitles.InProgress',
    OrderProgressSubtitlesPaid: 'OrderProgress.Subtitles.Paid',
    OrderProgressSubtitlesResolved: 'OrderProgress.Subtitles.Resolved',
    OrderProgressSubtitlesSearchingPartner: 'OrderProgress.Subtitles.SearchingPartner',
    OrderProgressSubtitlesStatusCreatedNoBids: 'OrderProgress.Subtitles.StatusCreatedNoBids',
    OrderProgressSubtitlesStatusCreatedWithBids: 'OrderProgress.Subtitles.StatusCreatedWithBids',
    OrderProgressSubtitlesStatusHelp: 'OrderProgress.Subtitles.StatusHelp',
    OrderProgressSubtitlesStatusStandardHelp: 'OrderProgress.Subtitles.StatusStandardHelp',
    OrderProgressSubtitlesStatusPricedHelp: 'OrderProgress.Subtitles.StatusPricedHelp',
    OrderProgressTitlesAwaitingApproval: 'OrderProgress.Titles.AwaitingApproval',
    OrderProgressTitlesCompleted: 'OrderProgress.Titles.Completed',
    OrderProgressTitlesInProgress: 'OrderProgress.Titles.InProgress',
    OrderProgressTitlesMain: 'OrderProgress.Titles.Main',
    OrderProgressTitlesPaid: 'OrderProgress.Titles.Paid',
    OrderProgressTitlesResolved: 'OrderProgress.Titles.Resolved',
    OrderProgressTitlesSearchingPartner: 'OrderProgress.Titles.SearchingPartner',
    OrderProgressTitlesStatusCreatedNoBids: 'OrderProgress.Titles.StatusCreatedNoBids',
    OrderProgressTitlesStatusCreatedWithBids: 'OrderProgress.Titles.StatusCreatedWithBids',
    OrdersButtonsLoadMore: 'Orders.Buttons.LoadMore',
    OrdersErrorFetch: 'Orders.Error.Fetch',
    OrdersHeaderSearchLabelsSearchPlaceholder: 'OrdersHeaderSearch.Labels.SearchPlaceholder',
    OrdersHeaderSearchTitlesFilter: 'OrdersHeaderSearch.Titles.Filter',
    OrdersHeaderSearchTitlesSearch: 'OrdersHeaderSearch.Titles.Search',
    OrdersLabelsNoFoundOrders: 'Orders.Labels.NoFoundOrders',
    OrdersLabelsNoOrders: 'Orders.Labels.NoOrders',
    OrdersTabsTitlesAllOrders: 'OrdersTabs.Titles.AllOrders',
    OrdersTabsTitlesCanceled: 'OrdersTabs.Titles.Canceled',
    OrdersTabsTitlesInProgress: 'OrdersTabs.Titles.InProgress',
    OrdersTabsTitlesPaid: 'OrdersTabs.Titles.Paid',
    OrdersTabsTitlesResolved: 'OrdersTabs.Titles.Resolved',
    OrdersTabsTitlesWaitingForAction: 'OrdersTabs.Titles.WaitingForAction',
    OrdersTabsTitlesWaitingForBids: 'OrdersTabs.Titles.WaitingForBids',
    OrdersTabsTitlesCreatedAsPreorder: 'OrdersTabs.Titles.CreatedAsPreorder',
    OrderStepsButtonsBack: 'OrderSteps.Buttons.Back',
    OrderStepsButtonsDetailsStep: 'OrderSteps.Buttons.DetailsStep',
    OrderStepsButtonsInformationStep: 'OrderSteps.Buttons.InformationStep',
    OrderStepsButtonsJobsStep: 'OrderSteps.Buttons.JobsStep',
    OrderStepsButtonsNext: 'OrderSteps.Buttons.Next',
    OrderStepsButtonsPartnerStep: 'OrderSteps.Buttons.PartnerStep',
    OrderStepsButtonsServiceStep: 'OrderSteps.Buttons.ServiceStep',
    OrderStepsButtonsSummaryStep: 'OrderSteps.Buttons.SummaryStep',
    OrderStepsErrorsJobItemNotSelected: 'OrderSteps.Errors.JobItemNotSelected',
    OrderStepsInfoJobsStepFactual: 'OrderSteps.Info.JobsStepFactual',
    OrderStepsInfoJobsStepFixed: 'OrderSteps.Info.JobsStepFixed',
    OrderStepsInfoJobsStepStandard: 'OrderSteps.Info.JobsStepStandard',
    OrderStepsLabelsAddress: 'OrdersSteps.Labels.Address',
    OrderStepsLabelsContactPersonInput: 'OrderSteps.Labels.ContactPersonInput',
    OrderStepsLabelsContactPersonSelected: 'OrderSteps.Labels.ContactPersonSelected',
    OrderStepsLabelsContactPersonUser: 'OrderSteps.Labels.ContactPersonUser',
    OrderStepsLabelsContacts: 'OrdersSteps.Labels.Contacts',
    OrderStepsLabelsMaterialsSelectHelp: 'OrdersSteps.Labels.MaterialsSelectHelp',
    OrderStepsLabelsDeadlineDurationMonth: 'OrderSteps.Labels.DeadlineDurationMonth',
    OrderStepsLabelsDeadlineDurationSixMonths: 'OrderSteps.Labels.DeadlineDurationSixMonths',
    OrderStepsLabelsDeadlineDurationTwoWeeks: 'OrderSteps.Labels.DeadlineDurationTwoWeeks',
    OrderStepsLabelsDeadlineDurationWeek: 'OrderSteps.Labels.DeadlineDurationWeek',
    OrderStepsLabelsDeadlineInput: 'OrderSteps.Labels.DeadlineInput',
    OrderStepsLabelsDeadlineInputHelp: 'OrderSteps.Labels.DeadlineInputHelp',
    OrderStepsLabelsDetailsStepDescriptionFieldLabel: 'OrderSteps.Labels.DetailsStepDescriptionFieldLabel',
    OrderStepsLabelsDetailsStepExpensesCheckboxLabel: 'OrderSteps.Labels.DetailsStepExpensesCheckboxLabel',
    OrderStepsLabelsExpectedTimeAfterWork: 'OrderSteps.Labels.ExpectedTimeAfterWork',
    OrderStepsLabelsExpectedTimeAnytime: 'OrderSteps.Labels.ExpectedTimeAnytime',
    OrderStepsLabelsExpectedTimeInput: 'OrderSteps.Info.ExpectedTimeInput',
    OrderStepsLabelsExpectedTimeWeekend: 'OrderSteps.Labels.ExpectedTimeWeekend',
    OrderStepsLabelsExpectedTimeWorkingHours: 'OrderSteps.Labels.ExpectedTimeWorkingHours',
    OrderStepsLabelsHandyman: 'OrdersSteps.Labels.Handyman',
    OrderStepsLabelsJobsStepEmptyJobList: 'OrderSteps.Labels.JobsStepEmptyJobList',
    OrderStepsLabelsJobsStepFactualRadioLabel: 'OrderSteps.Labels.JobsStepFactualRadioLabel',
    OrderStepsLabelsJobsStepFixedRadioLabel: 'OrderSteps.Labels.JobsStepFixedRadioLabel',
    OrderStepsLabelsJobsStepInfoLabel: 'OrderSteps.Labels.JobsStepInfoLabel',
    OrderStepsLabelsJobsStepReadMoreLabel: 'OrderSteps.Labels.JobsStepReadMoreLabel',
    OrderStepsLabelsJobsStepJobListAmountLabel: 'OrderSteps.Labels.JobsStepJobListAmountLabel',
    OrderStepsLabelsJobsStepStandardRadioLabel: 'OrderSteps.Labels.JobsStepStandardRadioLabel',
    OrderStepsLabelsJobsStepWorkTypeNonStandardLabel: 'OrderSteps.Labels.JobsStepWorkTypeNonStandardLabel',
    OrderStepsLabelsJobsStepWorkTypeStandardLabel: 'OrderSteps.Labels.JobsStepWorkTypeStandardLabel',
    OrderStepsLabelsLocationStepPartnersCount: 'OrderSteps.Labels.LocationStepPartnersCount',
    OrderStepsLabelsLocationStepServiceUnavailableAnchor: 'OrderSteps.Labels.LocationStepServiceUnavailableAnchor',
    OrderStepsLabelsLocationStepServiceUnavailableInfo: 'OrderSteps.Labels.LocationStepServiceUnavailableInfo',
    OrderStepsLabelsLocationStepServiceUnavailableSubtitle: 'OrderSteps.Labels.LocationStepServiceUnavailableSubtitle',
    OrderStepsLabelsLocationStepServiceUnavailableTitle: 'OrderSteps.Labels.LocationStepServiceUnavailableTitle',
    OrderStepsLabelsMaterialsSelect: 'OrdersSteps.Labels.MaterialsSelect',
    OrderStepsLabelsMaterialsSelectClient: 'OrdersSteps.Labels.MaterialsSelectClient',
    OrderStepsLabelsMaterialsSelectHandyman: 'OrdersSteps.Labels.MaterialsSelectHandyman',
    OrderStepsLabelsOrderTrackingCheckboxLabel: 'OrderSteps.Labels.OrderTrackingCheckboxLabel',
    OrderStepsLabelsSendTrackingBySms: 'OrderSteps.Labels.SendTrackingBySms',
    OrderStepsLabelsSendTrackingByEmail: 'OrderSteps.Labels.SendTrackingByEmail',
    OrderStepsLabelsPhotos: 'OrdersSteps.Labels.Photos',
    OrderStepsLabelsServiceReceiverSelected: 'OrderSteps.Labels.ServiceReceiverSelected',
    OrderStepsLabelsServiceReceiverUser: 'OrderSteps.Labels.ServiceReceiverUser',
    OrderStepsLabelsSummaryStepCheckboxLabel: 'OrderSteps.Labels.SummaryStepCheckboxLabel',
    OrderStepsLabelsSummaryStepCheckboxLabelSecondPart: 'OrderSteps.Labels.SummaryStepCheckboxLabelSecondPart',
    OrderStepsLabelsSummaryStepUpdatesCheckboxLabel: 'OrderSteps.Labels.SummaryStepUpdatesCheckboxLabel',
    OrderStepsLabelsSummaryStepDeadlineMetaItem: 'OrderSteps.Labels.SummaryStepDeadlineMetaItem',
    OrderStepsLabelsSummaryStepFixedPriceMetaItem: 'OrderSteps.Labels.SummaryStepFixedPriceMetaItem',
    OrderStepsLabelsSummaryStepHourlyMetaItem: 'OrderSteps.Labels.SummaryStepHourlyMetaItem',
    OrderStepsLabelsSummaryStepInfoMetaItem: 'OrderSteps.Labels.SummaryStepInfoMetaItem',
    OrderStepsLabelsSummaryStepLocationMetaItem: 'OrderSteps.Labels.SummaryStepLocationMetaItem',
    OrderStepsLabelsSummaryStepPartner: 'OrderSteps.Labels.SummaryStepPartner',
    OrderStepsLabelsSummaryStepPressableCheckboxLabel: 'OrderSteps.Labels.SummaryStepPressableCheckboxLabel',
    OrderStepsLabelsSummaryStepPressableCheckboxLabelContract: 'OrderSteps.Labels.SummaryStepPressableCheckboxLabelContract',
    OrderStepsLabelsSummaryStepPressableCheckboxLabelPrivacy: 'OrderSteps.Labels.SummaryStepPressableCheckboxLabelPrivacy',
    OrderStepsLabelsSummaryStepServiceMetaItem: 'OrderSteps.Labels.SummaryStepServiceMetaItem',
    OrderStepsLabelsSummaryStepTitle: 'OrderSteps.Labels.SummaryStepTitle',
    OrderStepsLabelsSummaryStepPublicUserHeader: 'OrderSteps.Labels.SummaryStepPublicUserHeader',
    OrderStepsLabelsSummaryStepPublicUserInfo: 'OrderSteps.Labels.SummaryStepPublicUserInfo',
    OrderStepsLabelsSummaryStepNextTitle: 'OrderSteps.Labels.SummaryStepNextTitle',
    OrderStepsLabelsWorkTypeNonStandardInfoButton: 'OrderSteps.Labels.WorkTypeNonStandardInfoButton',
    OrderStepsLabelsWorkTypeNonStandardInfoDescription: 'OrderSteps.Labels.WorkTypeNonStandardInfoDescription',
    OrderStepsLabelsWorkTypeStandardInfoButton: 'OrderSteps.Labels.WorkTypeStandardInfoButton',
    OrderStepsLabelsWorkTypeStandardInfoDescription: 'OrderSteps.Labels.WorkTypeStandardInfoDescription',
    OrderStepsServiceSearch: 'OrderSteps.Service.Search',
    OrderStepsSummarySearchPartner: 'OrderSteps.Summary.Search.Partner',
    OrderStepsServiceSearchResults: 'OrderSteps.Service.Search.Results',
    OrderStepsSubtitlesDetailsStep: 'OrderSteps.Subtitles.DetailsStep',
    OrderStepsSubtitlesInformationStep: 'OrderSteps.Subtitles.InformationStep',
    OrderStepsSubtitlesJobsStep: 'OrderSteps.Subtitles.JobsStep',
    OrderStepsSubtitlesPartnerStep: 'OrderSteps.Subtitles.PartnerStep',
    OrderStepsSubtitlesPriceStepStep: 'OrderSteps.Subtitles.PriceStepStep',
    OrderStepsSubtitlesServiceStep: 'OrderSteps.Subtitles.ServiceStep',
    OrderStepsSubtitlesSummaryStep: 'OrderSteps.Subtitles.SummaryStep',
    OrderStepsTitlesDetailsStep: 'OrderSteps.Titles.DetailsStep',
    OrderStepsTitlesInformationStep: 'OrderSteps.Titles.InformationStep',
    OrderStepsTitlesJobsStep: 'OrderSteps.Titles.JobsStep',
    OrderStepsTitlesPartnerStep: 'OrderSteps.Titles.PartnerStep',
    OrderStepsTitlesPriceStepStep: 'OrderSteps.Titles.PriceStepStep',
    OrderStepsTitlesServiceStep: 'OrderSteps.Titles.ServiceStep',
    OrderStepsTitlesSummaryStep: 'OrderSteps.Titles.SummaryStep',
    OrderSuccessButtonsClose: 'OrderSuccess.Buttons.Close',
    OrderSuccessTextAutoBidFirst: 'OrderSuccess.Text.AutoBidFirst',
    OrderSuccessTextAutoBidSecond: 'OrderSuccess.Text.AutoBidSecond',
    OrderSuccessTextMultiPartnersFirst: 'OrderSuccess.Text.MultiPartnersFirst',
    OrderSuccessTextMultiPartnersSecond: 'OrderSuccess.Text.MultiPartnersSecond',
    OrderSuccessTextOnePartnerFirst: 'OrderSuccess.Text.OnePartnerFirst',
    OrderSuccessTextOnePartnerSecond: 'OrderSuccess.Text.OnePartnerSecond',
    OrderSuccessTitlesMultiPartners: 'OrderSuccess.Titles.MultiPartners',
    OrderSuccessTitlesOnePartner: 'OrderSuccess.Titles.OnePartner',
    OrderTabsDocumentsTabLabelsLoadingLink: 'OrderTabsDocumentsTab.Labels.LoadingLink',
    OrderTabsDocumentsTabLabelsReportLink: 'OrderTabsDocumentsTab.Labels.ReportLink',
    OrderTabsPartnerTabTitlesComment: 'OrderTabsPartnerTab.Titles.Comment',
    OrderTabsPartnerTabTitlesPriceChanges: 'OrderTabsPartnerTab.Titles.PriceChanges',
    OrderTabsTitlesDocuments: 'OrderTabs.Titles.Documents',
    OrderTabsTitlesInformation: 'OrderTabs.Titles.Information',
    OrderTabsTitlesPartner: 'OrderTabs.Titles.Partner',
    OrderTabsTitlesPartners: 'OrderCard.Labels.PartnersOffers',
    OrderTabsTitlesProgress: 'OrderTabs.Titles.Progress',
    PartnerCardButtonsAccept: 'PartnerCard.Buttons.Accept',
    PartnerCardButtonsCall: 'PartnerCard.Buttons.Call',
    PartnerCardLabelsAbout: 'PartnerCard.Labels.About',
    PartnerCardLabelsAveragePrice: 'PartnerCard.Labels.AveragePrice',
    PartnerCardLabelsAverageWorkDuration: 'PartnerCard.Labels.AverageWorkDuration',
    PartnerCardLabelsClientsCount: 'PartnerCard.Labels.ClientsCount',
    PartnerCardLabelsConfirmedOrderPrice: 'PartnerCard.Labels.ConfirmedOrderPrice',
    PartnerCardLabelsCustomersFeedback: 'PartnerCard.Labels.CustomersFeedback',
    PartnerCardLabelsDoneWorksAmount: 'PartnerCard.Labels.DoneWorksAmount',
    PartnerCardLabelsExperience: 'PartnerCard.Labels.Experience',
    PartnerCardLabelsHour: 'PartnerCard.Labels.Hour',
    PartnerCardLabelsIsCertified: 'PartnerCard.Labels.IsCertified',
    PartnerCardLabelsLastWorkDate: 'PartnerCard.Labels.LastWorkDate',
    PartnerCardLabelsMissingPlannedMaterialExpenses: 'PartnerCard.Labels.MissingPlannedMaterialExpenses',
    PartnerCardLabelsNotSpecifiedPlannedStartDate: 'PartnerCard.Labels.PlannedStartDateNotSpecified',
    PartnerCardLabelsPlannedMaterialExpenses: 'PartnerCard.Labels.PlannedMaterialExpenses',
    PartnerCardLabelsPlannedStartDate: 'PartnerCard.Labels.PlannedStartDate',
    PartnerCardLabelsTotalWorkCount: 'PartnerCard.Labels.TotalWorkCount',
    PartnerCardLabelsUnconfirmedOrderPrice: 'PartnerCard.Labels.UnconfirmedOrderPrice',
    PaymentButtonsOrders: 'Payment.Buttons.Orders',
    PaymentButtonsOutsideProviderNext: 'Payment.Buttons.OutsideProviderNext',
    PaymentButtonsTypeSelectionCancel: 'Payment.Buttons.TypeSelectionCancel',
    PaymentButtonsTypeSelectionNext: 'Payment.Buttons.TypeSelectionNext',
    PaymentLabelsOutsideProvider: 'Payment.Labels.OutsideProvider',
    PaymentLabelsPaymentTypeCash: 'Payment.Labels.PaymentTypeCash',
    PaymentLabelsPaymentTypeCashSubtitle: 'Payment.Labels.PaymentTypeCashSubtitle',
    PaymentLabelsPaymentTypeProvider: 'Payment.Labels.PaymentTypeProvider',
    PaymentLabelsPaymentTypeProviderSubtitle: 'Payment.Labels.PaymentTypeProviderSubtitle',
    PaymentSubTitlesFailed: 'Payment.SubTitles.Failed',
    PaymentSubTitlesPending: 'Payment.SubTitles.Pending',
    PaymentSubTitlesSuccess: 'Payment.SubTitles.Success',
    PaymentTitlesFailed: 'Payment.Titles.Failed',
    PaymentTitlesOutsideProvider: 'Payment.Titles.OutsideProvider',
    PaymentTitlesPending: 'Payment.Titles.Pending',
    PaymentTitlesSuccess: 'Payment.Titles.Success',
    PaymentTitlesTypeSelection: 'Payment.Titles.TypeSelection',
    PersonalCodeError: 'Personal.Code.Error',
    PersonalCodeLabel: 'Personal.Code.Label',
    PersonalCodeTypeLabel: 'Personal.Code.TypeLabel',
    ProfileButtonsCancel: 'Profile.Buttons.Cancel',
    ProfileButtonsEdit: 'Profile.Buttons.Edit',
    ProfileButtonsSubmit: 'Profile.Buttons.Submit',
    ProfileLabelsAllowMarketing: 'Profile.Labels.AllowMarketing',
    ProfileLabelsAllowRecieveEmailNotifications: 'Profile.Labels.AllowRecieveEmailNotifications',
    ProfileLabelsAllowRecieveSmsNotifications: 'Profile.Labels.AllowRecieveSmsNotifications',
    ProfileLabelsCompany: 'Profile.Labels.Company',
    ProfileLabelsCompanyCode: 'Profile.Labels.CompanyCode',
    ProfileLabelsCompanyName: 'Profile.Labels.CompanyName',
    ProfileLabelsCompanyRegistrationAddress: 'Profile.Labels.CompanyRegistrationAddress',
    ProfileLabelsCompanyVatCode: 'Profile.Labels.CompanyVatCode',
    ProfileLabelsEmail: 'Profile.Labels.Email',
    ProfileLabelsInvoiceAddress: 'Profile.Labels.InvoiceAddress',
    ProfileLabelsName: 'Profile.Labels.Name',
    ProfileLabelsPhone: 'Profile.Labels.Phone',
    ProfileLabelsSurname: 'Profile.Labels.Surname',
    ProfileMessagesUpdateSuccess: 'Profile.Messages.UpdateSuccess',
    ProfileTitlesInfoNeeded: 'Profile.Titles.InfoNeeded',
    ProfileTitlesUpdateForm: 'Profile.Titles.UpdateForm',
    RegisterButtonsAcceptConditions: 'Register.Buttons.AcceptConditions',
    RegisterButtonsHaveAccount: 'Register.Buttons.HaveAccount',
    RegisterButtonsHaveAccountLogin: 'Register.Buttons.HaveAccountLogin',
    RegisterButtonsLogin: 'Register.Buttons.Login',
    RegisterButtonsLogin1: 'Register.Buttons.Login1',
    RegisterButtonsPrivacy: 'Register.Buttons.Privacy',
    RegisterButtonsSubmit: 'Register.Buttons.Submit',
    RegisterButtonsTerms: 'Register.Buttons.Terms',
    RegisterErrorButtonsRedirect: 'RegisterError.Buttons.Redirect',
    RegisterErrorsPrivacyRequired: 'Register.Errors.PrivacyRequired',
    RegisterErrorsTermsRequired: 'Register.Errors.TermsRequired',
    RegisterErrorSubTitlesMain: 'RegisterError.SubTitles.Main',
    RegisterErrorTitlesMain: 'RegisterError.Titles.Main',
    RegisterLabelsCompanyCode: 'Register.Labels.CompanyCode',
    RegisterLabelsCompanyIban: 'Register.Labels.CompanyIban',
    RegisterLabelsCompanyName: 'Register.Labels.CompanyName',
    RegisterLabelsCompanyRegistrationAddress: 'Register.Labels.CompanyRegistrationAddress',
    RegisterLabelsCompanyVatCode: 'Register.Labels.CompanyVatCode',
    RegisterLabelsEmail: 'Register.Labels.Email',
    RegisterLabelsFirstName: 'Register.Labels.FirstName',
    RegisterLabelsInvoiceAddress: 'Register.Labels.InvoiceAddress',
    RegisterLabelsLastName: 'Register.Labels.LastName',
    RegisterLabelsMarketing: 'Register.Labels.Marketing',
    RegisterLabelsPassword: 'Register.Labels.Password',
    RegisterLabelsPasswordRepeat: 'Register.Labels.PasswordRepeat',
    RegisterLabelsPhone: 'Register.Labels.Phone',
    RegisterLabelsPhoneShort: 'Register.Labels.PhoneShort',
    RegisterLabelsPrivacy: 'Register.Labels.Privacy',
    RegisterLabelsRecieveEmailNotification: 'Register.Labels.RecieveEmailNotifications',
    RegisterLabelsRecieveSmsNotifications: 'Register.Labels.RecieveSmsNotifications',
    RegisterLabelsTerms: 'Register.Labels.Terms',
    RegisterSubTitlesMain: 'Register.SubTitles.Main',
    RegisterSuccessButtonsRedirect: 'RegisterSuccess.Buttons.Redirect',
    RegisterSuccessSubTitlesMain: 'RegisterSuccess.SubTitles.Main',
    RegisterSuccessTitlesMain: 'RegisterSuccess.Titles.Main',
    RegisterTitlesMain: 'Register.Titles.Main',
    RegisterTypesEntity: 'Register.IsCompanyTypes.Entity',
    RegisterTypesIndividual: 'Register.IsCompanyTypes.Individual',
    ResetPasswordButtonsLogin: 'ResetPassword.Buttons.Login',
    ResetPasswordButtonsSubmit: 'ResetPassword.Buttons.Submit',
    ResetPasswordConfirmButtonsSubmit: 'ResetPasswordConfirm.Buttons.Submit',
    ResetPasswordConfirmLabelsPassword: 'ResetPasswordConfirm.Labels.Password',
    ResetPasswordConfirmLabelsPasswordRepeat: 'ResetPasswordConfirm.Labels.PasswordRepeat',
    ResetPasswordConfirmSubTitlesSubTitle: 'ResetPasswordConfirm.SubTitles.SubTitle',
    ResetPasswordConfirmTitlesTitle: 'ResetPasswordConfirm.Titles.Title',
    ResetPasswordErrorButtonsRedirect: 'ResetPasswordError.Buttons.Redirect',
    ResetPasswordErrorSubTitlesSubTitle: 'ResetPasswordError.SubTitles.SubTitle',
    ResetPasswordErrorTitlesTitle: 'ResetPasswordError.Titles.Title',
    ResetPasswordLabelsEmail: 'ResetPassword.Labels.Email',
    ResetPasswordSubTitlesMain: 'ResetPassword.SubTitles.Main',
    ResetPasswordSuccessButtonsRedirect: 'ResetPasswordSuccess.Buttons.Redirect',
    ResetPasswordSuccessSubTitlesSubTitle: 'ResetPasswordSuccess.SubTitles.SubTitle',
    ResetPasswordSuccessTitlesTitle: 'ResetPasswordSuccess.Titles.Title',
    ResetPasswordTitlesMain: 'ResetPassword.Titles.Main',
    SelectCountryButtonsNext: 'SelectCountry.Buttons.Next',
    SelectCountryTitlesMain: 'SelectCountry.Labels.Main',
    SelectServiceReceiverLabelsAddReceiver: 'SelectServiceReceiver.Labels.AddReceiver',
    UploadErrorTooManyFiles: 'Upload.Field.TooManyFiles',
    UploadErrorUnsupportedFile: 'Upload.Field.UnsupportedFile',
    UploadFieldButton: 'Upload.Field.Button',
    UploadFieldText: 'Upload.Field.Text',
    UploadHelperText: 'Upload.Helper.Text',
    UploadTitleMain: 'Upload.Title.Main',
    YupCyrillicErrorMessage: 'Yup.Cyrillic.Error.Message',
    BidJobScopeTitle: 'Bid.JobScope.Title',
    BidJobScopeDescription: 'Bid.JobScope.Description',
    BidJobScopePartner: 'Bid.JobScope.Partner',
    BidJobScopeClose: 'Bid.JobScope.Close',
    OrderJobScope: 'Order.JobScope',
    MtcTitle: 'Mtc.Title',
    MtcCompanyName: 'Mtc.CompanyName',
    MtcCompanyCode: 'Mtc.CompanyCode',
    MtcCompanyVat: 'Mtc.CompanyVat',
    MtcCompanyIban: 'Mtc.CompanyIban',
    MtcMaterialPrice: 'Mtc.MaterialPrice',
    MtcInvoiceInfo: 'Mtc.MtcInvoiceInfo',
};
