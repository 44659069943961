import { FC, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import i18next from 'i18next';

import { translates } from '../../utilities';
import { FieldWrapper } from './components/wrapper';
import { Toast } from '../toast';
import { Select } from '../select';
import { ReactComponent as PinIcon } from '../../assets/images/pin-green.svg';
import { ConfigContext } from '../../contexts';

interface FormFieldAutocompleteGoogleMapsProps {
    label: string;
    name: string;
    size?: string;
    country: string;
    longitudeFieldName: string;
    latitudeFieldName: string;
    disabled?: boolean;
    locationUpdated?: () => void;
    restrictByCountry?: boolean;
}

export const FormFieldAutocompleteGoogleMaps: FC<FormFieldAutocompleteGoogleMapsProps> = ({
    name,
    label,
    country,
    longitudeFieldName,
    latitudeFieldName,
    restrictByCountry = false,
    locationUpdated,
}) => {
    const [field, meta, helpers] = useField(name);
    const { isSubmitting, values, setFieldValue } = useFormikContext();
    const getValue = (path: string) => {
        return path.split('.').reduce((o, i) => (o as any)[i], values);
    };

    const { language } = useContext(ConfigContext);
    const languageRef = useRef(language);
    const [filled, setFilled] = useState(!!getValue(name));
    const [focused, setFocused] = useState(false);
    const { suggestions, setValue, clearCache, clearSuggestions } = usePlacesAutocomplete({
        cacheKey: `place-${language}`,
        requestOptions: {
            language: language.toLowerCase(),
            componentRestrictions: { country: restrictByCountry ? country : undefined },
        },
    });

    useEffect(() => {
        if (language !== languageRef.current) {
            clearCache(`place-${languageRef}`);
            clearSuggestions();
            languageRef.current = language;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, languageRef]);

    const setMultipleValues = (address: string, lat: number, lng: number) => {
        helpers.setValue(address);
        setFieldValue(latitudeFieldName, lat);
        setFieldValue(longitudeFieldName, lng);
    };

    const change = (value: string) => {
        setValue(value);
    };

    const select = async (value: string) => {
        if (!value) {
            setMultipleValues('', 0, 0);
            return;
        }
        try {
            const results = await getGeocode({ address: value });
            const { lat, lng } = await getLatLng(results[0]);
            setMultipleValues(results[0].formatted_address, lat, lng);
            if (locationUpdated) {
                locationUpdated();
            }
        } catch {
            Toast.error(i18next.t(translates.GlobalMessagesGeolocationParseError));
        }
    };

    const handleFocus = () => {
        setFilled(true);
        setFocused(true);
    };

    const handleBlur = () => {
        setFilled(!!getValue(name));
        setTimeout(() => {
            helpers.setTouched(true);
        }, 500);
        setFocused(false);
    };

    return (
        <FieldWrapper name={name} label={label} filled={filled} submitting={isSubmitting} focused={focused}>
            <Select
                name={name}
                error={meta.touched && !!meta.error}
                options={suggestions.data.map((item) => ({
                    value: item.description,
                    label: item.description,
                }))}
                value={getValue(name) as string}
                onSearch={change}
                onChange={select}
                onFocus={handleFocus}
                onBlur={handleBlur}
                customIcon={PinIcon as unknown as ReactElement}
                isClearable={true}
            />
        </FieldWrapper>
    );
};
