import { Method } from 'axios';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import React, { createContext, FC, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { useLocalizationApi } from '../hooks/useLocalizationApi';
import { getCountry, getCountryLanguage, getCountryNotificationsUrl } from '../utilities/countryDefaults';

export interface ContactInfoModel {
    phone: string;
    email: string;
}

type InitialStatetype = {
    country: string;
    language: string;
    languages: string[];
    contactInfo: ContactInfoModel;
    signalRConnection: HubConnection | null;
    setSignalRConnection: (connection: HubConnection | null) => void;
    setLanguage: (language: string) => void;
    setLanguages: (languages: string[]) => void;
    setContactInfo: (contactInfo: ContactInfoModel) => void;
};

const enum Actions {
    SET_LANGUAGE = 'SET_LANGUAGE',
    SET_LANGUAGES = 'SET_LANGUAGES',
    SET_SIGNALR_CONNECTION = 'SET_SIGNALR_CONNECTION',
    SET_ROUTE_CONFIG = 'SET_ROUTE_CONFIG',
    SET_CONTACT_INFO = 'SET_CONTACT_INFO',
}

type Action =
    | { type: Actions.SET_LANGUAGE; value: string }
    | { type: Actions.SET_LANGUAGES; value: string[] }
    | { type: Actions.SET_CONTACT_INFO; value: ContactInfoModel }
    | { type: Actions.SET_SIGNALR_CONNECTION; value: HubConnection | null };

const reducer = (state: InitialStatetype, action: Action) => {
    switch (action.type) {
    case Actions.SET_LANGUAGE:
        localStorage.setItem('language', action.value);
        i18next.changeLanguage(action.value);
        return { ...state, language: action.value };
    case Actions.SET_LANGUAGES:
        return { ...state, languages: action.value };
    case Actions.SET_CONTACT_INFO:
        localStorage.setItem('contactInfo', JSON.stringify(action.value));
        return { ...state, contactInfo: action.value };
    case Actions.SET_SIGNALR_CONNECTION:
        return { ...state, signalRConnection: action.value };
    default:
        return state;
    }
};

const getLanguages = (country: string) => {
    return {
        method: 'GET' as Method,
        url: `languages/${country}/Client`,
    };
};

const getContactInfo = () => {
    const contactInfo = localStorage.getItem('contactInfo');
    return contactInfo ? JSON.parse(contactInfo) : null;
};

export const ConfigContext = createContext<InitialStatetype>({
    country: '',
    language: '',
    languages: [],
    contactInfo: {} as ContactInfoModel,
    signalRConnection: null,
    setSignalRConnection: () => {},
    setLanguage: () => {},
    setLanguages: () => {},
    setContactInfo: () => {}
});

export const ConfigProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const country = getCountry();
    const defaultLanguage = getCountryLanguage(country);
    const [state, dispatch] = useReducer(reducer, {
        country: getCountry(),
        language: defaultLanguage,
        languages: [defaultLanguage],
        contactInfo: getContactInfo(),
        signalRConnection: null,
        setSignalRConnection: () => {},
        setLanguage: () => {},
        setLanguages: () => {},
        setContactInfo: () => {}
    });
    const localizationApi = useLocalizationApi();
    const getLocalizationConfig = (language: string) => {
        dispatch({ type: Actions.SET_LANGUAGE, value: language });

        localizationApi(getLanguages(state.country))
            .then((res) => {
                dispatch({ type: Actions.SET_LANGUAGES, value: res as string[] });
            })
            .catch(() => {
                dispatch({ type: Actions.SET_LANGUAGES, value: [language] });
            });
    };

    useEffect(() => {
        getLocalizationConfig(localStorage.getItem('language') || defaultLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.country]);

    useEffect(() => {
        const notificationsUrl = getCountryNotificationsUrl(country);
        if (notificationsUrl) {
            const connection = new HubConnectionBuilder().withUrl(notificationsUrl).withAutomaticReconnect().build();

            connection.start().then(() => {});

            dispatch({ type: Actions.SET_SIGNALR_CONNECTION, value: connection });
        }
    }, []);

    const value = {
        ...state,
        setSignalRConnection: (connection: HubConnection | null) => {
            dispatch({ type: Actions.SET_SIGNALR_CONNECTION, value: connection });
        },
        setLanguage: (value: string) => {
            if (state.languages.includes(value)) {
                dispatch({ type: Actions.SET_LANGUAGE, value });
            }
        },
        contactInfo: state.contactInfo,
        setContactInfo: (value: ContactInfoModel) => {
            dispatch({ type: Actions.SET_CONTACT_INFO, value });
        },
    };

    return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

ConfigProvider.propTypes = {
    children: PropTypes.any,
};
